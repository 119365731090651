import { ReactNode } from 'react';

export type FormikInputType = {
  name: string;
  placeholder?: string;
  label?: ReactNode | false;
  info?: string;
  disabled?: boolean;
};

export const STANDARD_INPUT_HEIGHT = 44;
