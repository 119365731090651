import bharatLaghuUdyam from './bharat-laghu-udyam.png';
import bharatSookshmaUdyam from './bharat-sookshma-udyam.png';
import FireLossProfit from './fire-loss-of-profit.png';
import Griha from './griha.png';
import StandardFireAndSpecial from './standard-fire-and-special-perils.png';


export const FireInsuranceImages = {
  bharatLaghuUdyam,
  bharatSookshmaUdyam,
  FireLossProfit,
  Griha,
  StandardFireAndSpecial,
};
