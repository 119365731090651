import { Dispatch, forwardRef, RefObject, SetStateAction } from 'react';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import { twMerge } from 'tailwind-merge';
import { STANDARD_INPUT_HEIGHT } from '../forms';

export const LiveTextBox = forwardRef(
  (
    props: Omit<React.HTMLProps<HTMLInputElement>, 'value'> & {
      value: string;
      setValue: Dispatch<SetStateAction<string>>;
    },
    ref,
  ) => {
    const { setValue, ...rest } = props;
    return (
      <div
        className={twMerge(
          `rounded-large border-1.5 border-grey-300 flex items-center gap-x-2 px-3.5 ${props.className}`,
        )}
        style={{
          height: STANDARD_INPUT_HEIGHT,
        }}
      >
        <SearchLineIcon className="text-grey-500" />
        <input
          {...rest}
          ref={ref as RefObject<HTMLInputElement>}
          type="text"
          className="flex-1 bg-transparent focus:outline-none"
          placeholder={props.placeholder || 'Search'}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    );
  },
);
