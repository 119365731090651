import endowment from './endowment.png';
import term from './term.png';
import uulp from './uulp.png';
import wholeLife from './wholeLife.png';

export const LifeInsuranceImages = {
  endowment,
  term,
  uulp,
  wholeLife
};
