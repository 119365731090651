/* eslint-disable max-lines */
import { useState } from 'react';
import { NavProductType } from './nav-products';
import { productsList } from './product-list';
import { InsuranceNavItems } from './insurance-nav-items';
import { InsuranceCard } from './insurance-product-card';
import { InsideNavItems } from './inside-nav-items';
import { FeatureProductsCard, FeatureProductsCardData } from './feature-products-cards';

const ProductsSubHeader = () => {
  const [products, setProducts] = useState<NavProductType>(productsList[0]);
  return (
    <div className="">
      <div className="grid grid-cols-12 gap-6 ">
        <div className="col-span-3 flex  h-full w-full gap-1   py-3 ">
          <div className="w-full">
            {productsList.map((e, idx) => (
              <div
                className="flex w-full flex-col"
                key={idx}
                onMouseEnter={() => setProducts(e)}
                onMouseLeave={() => setProducts(e)}
              >
                <InsuranceNavItems icon={e.icon} heading={e.name} title={e.name} themeColor={e.theme} />
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-6 flex flex-col gap-6 py-3">
          <InsuranceCard
            name={products.name}
            desc={products.description}
            link={products.cardLink as string}
            themeColor={products.theme}
            cardImage={products.cardImages}
          />
          <div className=" grid grid-cols-2 gap-x-2">
            {products.products.map((e, idx) => (
              <div key={idx}>
                <InsideNavItems
                  name={e.name}
                  productIcon={e.productIcon}
                  heading={products.name}
                  themeColor={products.theme}
                />
              </div>
            ))}
          </div>

        </div>
        <div className='col-span-3 border-l border-grey-200 '>
          <div className='p-3 flex flex-col gap-[10px]'>

            {FeatureProductsCardData.map((product, index) =>
              <div key={index}>
                <FeatureProductsCard title={product.title} theme={product.theme} imageUrl={product.imageUrl} link={product.link} description={product.description} buttonColor={product.buttonColor} mobileImage={product.mobileImage} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsSubHeader;

