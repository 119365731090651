import { slugify } from '@prishapolicy/shared/ui';
import ProductServiceNavCard from '../product-service-nav-card';
import { RemixiconReactIconComponentType } from 'remixicon-react';
export type NonProductSubHeaderProps = {
  items: {
    name: string;
    description: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
    productIcon?: RemixiconReactIconComponentType;
    activeColor?: string;
  }[];
  prefix: string;
};

const NonProductSubHeader = ({ items, prefix }: NonProductSubHeaderProps) => {
  return (
    <>
      {[0, 1, 2].map((i) => {
        return (
          <li className="mr-10 grid grid-cols-2 gap-5 place-items-start w-fill" key={i}>
            {items.slice(i * 2, (i + 1) * 2).map((item, idx) => {
              return (
                <ProductServiceNavCard
                  productIcon={item.productIcon}
                  heading={item.name}
                  href={`/${prefix}/${slugify(item.name)}`}
                  active={false}
                  description={item.description}
                  icon={item.icon}
                  key={idx}
                  activeColor={item.activeColor}
                />
              );
            })}
          </li>
        );
      })}
    </>
  );
};

export default NonProductSubHeader;
