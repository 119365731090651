import { BrandLogoBlack, IRDAILogo } from '@prishapolicy/shared/assets';
import { Badge } from '@prishapolicy/shared/ui';
import Image from 'next/image';
import Link from 'next/link';
import { RemixiconReactIconComponentType } from 'remixicon-react';
import FacebookBoxFillIcon from 'remixicon-react/FacebookBoxFillIcon';
import InstagramFillIcon from 'remixicon-react/InstagramFillIcon';
import LinkedinBoxFillIcon from 'remixicon-react/LinkedinBoxFillIcon';
import YoutubeFillIcon from 'remixicon-react/YoutubeFillIcon';
import Mission from './mission';

const FooterColumn = (props: {
  heading: string;
  links: {
    text: string;
    link: string;
    disabled?: boolean;
  }[];
}) => {
  return (
    <div className="mt-6 xl:mt-0">
      <h2 className="heading-6">{props.heading}</h2>
      <ul>
        {props.links.map((link) => {
          return (
            <li key={link.text} className="my-2 flex cursor-pointer items-center gap-2 xl:gap-0.5">
              <span className={`whitespace-nowrap ${link.disabled === true ? 'text-grey-700' : ''}`}>
                <Link href={link.disabled ? '' : link.link}>{link.text}</Link>
              </span>
              {link.disabled === true && <Badge text="Coming Soon" />}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const SSRFooter = () => {
  const productLinks = [
    {
      text: 'Travel Insurance',
      link: '/products/travel-insurance',
    },
    {
      text: 'Motor Insurance',
      link: '/products/motor-insurance',
    },
    {
      text: 'Health And Accidental',
      link: '/products/health-and-accidental',
    },
    {
      text: 'Fire Insurance',
      link: '/products/fire-insurance',
    },
    {
      text: 'Engineering Products',
      link: '/products/engineering-insurance',
    },
    {
      text: 'Liability Insurance',
      link: '/products/liability-insurance',
    },
    {
      text: 'Marine Insurance',
      link: '/products/marine-insurance',
    },
    {
      text: 'Life Insurances',
      link: '/products/life-insurance',
    },
    {
      text: 'Other Insurances',
      link: '/products/other-insurances',
    },
  ];

  const serviceLinks = [
    {
      text: 'Risk Inspection',
      link: '/services/risk-inspection',
    },
    {
      text: 'Insurance Audit',
      link: '/services/insurance-audit',
    },
    {
      text: 'Claim Settlement',
      link: '/services/claim-settlement',
    },
    {
      text: 'Designing Insurance Program',
      link: '/services/designing-insurance-program',
    },
  ];

  const pospLinks = [
    {
      text: 'Who are POSPs',
      link: '/about/about-our-posps/',
    },
    {
      text: 'Become a POSP',
      link: '/about/about-our-posps/',
    },
    {
      text: 'Find a POSP',
      link: '/products/motor-insurance',
      disabled: true,
    },
  ];

  const resourceLinks = [
    {
      text: 'Blogs',
      link: '/resources/blog',
    },
    {
      text: 'Glossary',
      link: '/resources/glossary',
    },
    {
      text: 'Insurance Resources',
      link: '/resources/insurance-resources',
    },
    {
      text: 'Calculators',
      link: '/resources/calculators',
      disabled: true,
    },
    {
      text: 'Academy',
      link: '/resources/academy',
      disabled: true,
    },
  ];

  const companyLinks = [
    {
      text: 'About us',
      link: '/about/who-we-are',
    },
    {
      text: 'Careers',
      link: '/about/careers',
    },
    {
      text: 'Connect',
      link: '/contact-us',
    },
    {
      text: 'Privacy Policy',
      link: '/about/privacy-policy',
    },
    {
      text: 'Terms and Conditions',
      link: '/about/terms-and-conditions',
    },
  ];

  const footerSocialIcons: [RemixiconReactIconComponentType, string, string][] = [
    [FacebookBoxFillIcon, '#545454', 'https://www.facebook.com/PrishaPolicy/'],
    [InstagramFillIcon, '#545454', 'https://www.instagram.com/prishapolicy'],
    [LinkedinBoxFillIcon, '#545454', 'https://in.linkedin.com/company/prishapolicy'],
    [YoutubeFillIcon, '#545454', 'https://www.youtube.com/c/PrishaPolicy'],
  ];
  return (
    <footer className="bg-secondary pb-10 xl:px-3 ">
      <div className=" ">
        <div className="xl:bg-secondary bg-primary block py-5 text-white  xl:hidden xl:px-0 xl:text-black">
          <Mission />
        </div>
        <div className="container grid-cols-12 xl:grid">
          <div className="hidden text-white xl:col-span-4 xl:block xl:px-0 xl:text-black">
            <Mission />
          </div>
          <div className="xl:mt-15 mt-10 grid-cols-4 gap-x-7 xl:col-span-8 xl:grid">
            <div>
              <FooterColumn heading="Insurance Products" links={productLinks} />
            </div>
            <div className="flex flex-col justify-between">
              <FooterColumn heading="Our Services" links={serviceLinks} />
              <FooterColumn heading="Our POSPs" links={pospLinks} />
            </div>
            <div>
              <FooterColumn heading="Resources" links={resourceLinks} />
            </div>
            <div>
              <FooterColumn heading="Company" links={companyLinks} />
            </div>
          </div>
        </div>
        <div className="border-t-primary container	 mt-3 grid-cols-4 border-0 pt-7  xl:mt-7 xl:grid xl:border-t-2">
          <address className="col-span-2">
            <h2 className="heading-6 not-italic">Prisha Insurance Brokers Pvt. Ltd</h2>
            <p className="my-2 not-italic">
              307, 3rd floor, Spaze Platinum Tower, Sohna Road, Sector 47, Gurugram, Haryana - 122018
            </p>
            <div className="flex w-full flex-col gap-y-2 xl:flex-row xl:items-center xl:justify-between">
              <div className="order-last mt-5 xl:order-first xl:mt-0">
                <Image src={IRDAILogo} alt="IRDAI" width={110.88} />
              </div>

              <div className="not-italic">
                <p>IRDA License No: 680</p>
                <p className="mt-2">License valid till: 02.10.25</p>
              </div>
              <div className="not-italic">
                <p>CIN NO: U66000HR2011PTC044709</p>
                <p className="mt-2">Email: info@prishapolicy.com</p>
              </div>
            </div>
          </address>
          <div className="col-span-2 flex flex-col xl:items-end">
            <BrandLogoBlack className="my-3 mt-6 xl:mt-0" />
            <div className="my-4 flex grid-cols-4 gap-x-5 xl:grid">
              {footerSocialIcons.map((row, idx) => {
                const Icon = row[0];
                return (
                  <a
                    key={idx}
                    href={row[2]}
                    aria-label={`PrishaPolicy ${row[2].match(/\.(.+)\.com/)?.pop()}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon color={row[1]} />
                  </a>
                );
              })}
            </div>
            <p>Copyright © 2024 Prisha Insurance Brokers Pvt Ltd. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export const Footer = SSRFooter;
