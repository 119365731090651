import { useState } from 'react';
import { RemixiconReactIconComponentType } from 'remixicon-react';

type ProductServiceNavCardProps = {
  onClick?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  heading: string;
  active: boolean;
  href?: string;
  description: string;
  activeColor?: string;
  productIcon?: RemixiconReactIconComponentType;
};

export const CardContent = (props: ProductServiceNavCardProps) => {
  const [hovered, setIsHovered] = useState(false);
  return (
    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={()=>setIsHovered(false)}>
      <div className={`w-full cursor-pointer rounded-md p-0 xl:p-3 xl:w-[307px]  ${hovered && 'xl:hover:bg-blue-100'}`}>
        <div className="flex gap-3">
          <div className="top-0">
            <div
              className={`text-primary   flex justify-center rounded-full p-[10px] ${
                hovered ? 'bg-white' : 'xl:bg-surface-grey'
              } `}
            >
              {props.productIcon && <props.productIcon />}
            </div>
          </div>
          <div className="flex flex-col">
            <h1 className="text-body-1 font-medium">{props.heading}</h1>
            <p className="text-body-5 font-normal">{props.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
const ProductServiceNavCard = (props: ProductServiceNavCardProps) => {
  const disabled = props.description.includes('Coming Soon');
  return props.href && !disabled ? (
    <a href={props.href}>
      <CardContent {...props} />
    </a>
  ) : (
    <div onClick={props.onClick} className={disabled ? 'opacity-50' : ''}>
      <CardContent {...props} />
    </div>
  );
};

export default ProductServiceNavCard;
