import { Button, LiveTextBox, unslugify, useFuzzySearch } from '@prishapolicy/shared/ui';
import axios from 'axios';
import { useRouter } from 'next/router';
import { RefObject, useEffect, useRef, useState } from 'react';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';

const SearchResults = ({
  query,
  containerRef,
  close,
}: {
  query: string;
  close: () => void;
  containerRef: RefObject<HTMLDivElement>;
}) => {
  const router = useRouter();
  const [searchResults, setSearchResults] = useState<{ url: string; name: string }[]>([]);
  useEffect(() => {
    axios
      .get('https://www.prishapolicy.com/sitemap.xml')
      .then((res) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(res.data, 'text/xml');
        const urls = xml.getElementsByTagName('url');
        const urlsArray = Array.from(urls);
        const urlsArray2 = urlsArray.map((url) => url.getElementsByTagName('loc')[0].innerHTML);
        setSearchResults(
          urlsArray2.map((url) => ({
            url: url.split('.com').at(-1) || '',
            name: unslugify(url.split('/').at(-2)),
          })),
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const fuzzyResults = useFuzzySearch(
    searchResults.filter((v) => v.url.length > 1),
    query,
    (d) => ({ data: d.name }),
  );
  return (
    <div
      className="rounded-large absolute z-50 bg-white"
      style={{
        top: containerRef.current?.getBoundingClientRect().bottom || 0 + 8,
        left: containerRef.current?.getBoundingClientRect().left,
        width: containerRef.current?.getBoundingClientRect().width,
      }}
    >
      {fuzzyResults.slice(0, 5).map((result, idx) => {
        return (
          <div
            className="first:rounded-t-large last:rounded-b-large border-grey-100 hover:bg-grey-100 flex cursor-pointer items-center border p-4 font-medium"
            onClick={() => {
              close();
              router.push(result.url);
            }}
            key={idx}
          >
            <SearchLineIcon />
            <div className="ml-2">{result.name}</div>
          </div>
        );
      })}
    </div>
  );
};

const Search = () => {
  const [showResults, setShowResults] = useState(false);
  const [query, setQuery] = useState('');
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef]);
  // const { width } = useWindowWidth();
  return (
    <div className=" flex w-full lg:mb-0">
      <div
        className=" flex w-full items-center lg:mt-0"
        ref={divRef}
        onFocus={() => {
          setShowResults(true);
        }}
      >
        <div className="flex flex-1 flex-col">
          <LiveTextBox value={query} setValue={setQuery} />
          {showResults && (
            <SearchResults
              query={query}
              containerRef={divRef}
              close={() => {
                setQuery('');
                setShowResults(false);
              }}
            />
          )}
        </div>
      </div>
      {/* <div className={'ml-3 hidden  lg:flex'}>
        <Button href="/about/about-our-posps" text="Become a POS" />
      </div> */}
    </div>
  );
};

export default Search;
