import audit from './audit.png';
import claim from './claim.png';
import design from './design.png';
import risk from './risk.png';
import service from './service.png';

export default {
  audit,
  claim,
  design,
  risk,
  service,
};
