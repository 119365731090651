import Image from 'next/image';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';

interface InsuranceNavItemsProps {
  icon: any;
  heading: string;
  title: string;
  themeColor?: string;
}

export const InsuranceNavItems = ({ icon, heading, title, themeColor }: InsuranceNavItemsProps) => {
  return (
    <div>
      <div className=" hover:bg-surface-grey flex w-full cursor-pointer items-center justify-between rounded-lg p-3 font-medium hover:font-bold ">
        <div className="flex items-center gap-3">
          <Image src={icon} alt={heading} width={28} height={21} />
          <h1 className="text-body-1 items-center  ">{title}</h1>
        </div>
        <div className="">
          <ArrowRightSLineIcon />
        </div>
      </div>
    </div>
  );
};
