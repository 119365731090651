import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon';
import { NavCardTemplate } from '../blogs-card';
import startups from './startups.png';
import Link from 'next/link';

export const ArticleData = [
  {
    heading: 'Top 44 in the Most Innovative Commercial Insurance Companies',
    blogPoster: startups,
    description:
      'August this year, PrishaPolicy was rated in the top 44 most innovative...',
    link: 'https://beststartup.in/44-india-based-commercial-insurance-companies-the-most-innovative-commercial-insurance-companies/',
  },
  {
    heading: 'The Most Innovative Auto Insurance Companies',
    blogPoster: startups,
    description: 'Out of the 4,00,000 Indian start-ups tracked, PrishaPolicy was placed...',
    link: 'https://beststartup.in/81-india-based-auto-insurance-companies-the-most-innovative-auto-insurance-companies/',
  },
];

export const LatestNews = () => {
  return (
    <div className="bg-surface-grey flex flex-col gap-3 rounded-lg p-8">
      <h1 className="text-body-1 text-primary pl-3 font-bold">Latest News and Releases</h1>
      {ArticleData.map((e, idx) => (
        <div key={idx}>
          <NavCardTemplate heading={e.heading} Poster={e.blogPoster} description={e.description} link={e.link} />
        </div>
      ))}
      <Link href={'/about/press-and-awards/'} >
        <div className="text-body-1 text-primary flex gap-3 pl-3 font-bold cursor-pointer">
          <span>All News Articles</span>
          <ArrowRightLineIcon />
        </div>
      </Link>
    </div>
  );
};
