import { authScopesWithoutPrefix, prodPublicADB2CConfig, stagingPublicADB2CConfig } from '../auth-config/authConfig';

export type EnvType = 'DEV' | 'STAGING' | 'PROD' | undefined;
export type getUrlsParams = {
  env?: EnvType;
};

export type getUrlsReturnType = {
  urls: { backendCore: string; purchaseFlow: string; w3: string; vantage: string };
  vapidPublicKey: string;
} & typeof stagingPublicADB2CConfig;

export const getFrontendEnvData = (props?: getUrlsParams): getUrlsReturnType => {
  const envData = {
    STAGING: {
      urls: {
        backendCore: ['https://backend-core-staging.lemonmoss-87d5ddf4.eastasia.azurecontainerapps.io'],
        purchaseFlow: ['https://pf.taketheleap.co.in'],
        w3: ['https://w3.taketheleap.co.in'],
        vantage: ['https://vantage.taketheleap.co.in'],
      },
      vapidPublicKey: 'BOoBt_u8om0NiYQSIwi9FGkAiSQKtuFYsbpviGfklFz2t1zJGyOE7GioMY2Dv7b-BAl8nxZEgKY3IDgOP4VyVeA',
      ...stagingPublicADB2CConfig,
    },
    PROD: {
      urls: {
        backendCore: ['https://backend-core-prod.azurewebsites.net'],
        purchaseFlow: ['https://buy.prishapolicy.com'],
        w3: ['https://www.prishapolicy.com', 'https://prishapolicy.com'],
        vantage: ['https://vantage.prishapolicy.com'],
      },
      vapidPublicKey: 'BOoBt_u8om0NiYQSIwi9FGkAiSQKtuFYsbpviGfklFz2t1zJGyOE7GioMY2Dv7b-BAl8nxZEgKY3IDgOP4VyVeA',
      ...prodPublicADB2CConfig,
    },
    DEV: {
      urls: {
        backendCore: ['http://localhost:3333'],
        purchaseFlow: ['http://localhost:5000'],
        w3: ['http://localhost'],
        vantage: ['http://localhost:4200'],
      },
      vapidPublicKey: 'BOoBt_u8om0NiYQSIwi9FGkAiSQKtuFYsbpviGfklFz2t1zJGyOE7GioMY2Dv7b-BAl8nxZEgKY3IDgOP4VyVeA',
      ...stagingPublicADB2CConfig,
    },
  };

  let guessEnv: NonNullable<EnvType> = 'DEV';
  if (typeof window !== 'undefined' && (!props || !props.env)) {
    const foundEnv = Object.keys(envData).find((env) => {
      return Object.values(envData[env as keyof typeof envData].urls).some((urls) => {
        return urls.some((url) => window.location.hostname === new URL(url).hostname);
      });
    });
    if (foundEnv) {
      guessEnv = foundEnv as NonNullable<EnvType>;
    }
  }

  const finalEnv = envData[props && props.env ? props.env : guessEnv];
  const finalURLs = {
    ...finalEnv,
    urls: {
      backendCore: finalEnv.urls.backendCore[0],
      purchaseFlow: finalEnv.urls.purchaseFlow[0],
      w3: finalEnv.urls.w3[0],
      vantage: finalEnv.urls.vantage[0],
    },
  };

  return finalURLs;
};

const frontendEnvData = getFrontendEnvData();
// console.log('ENV DATA', frontendEnvData);
export const authScopes = [
  `https://${frontendEnvData.B2C_TENANT_NAME}/${frontendEnvData.BACKEND_CORE_CLIENT_ID}/${authScopesWithoutPrefix[0]}`,
];
export const authRequestWithScopes = {
  scopes: authScopes,
};

export const msalConfig = {
  auth: {
    clientId: frontendEnvData.VANTAGE_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: `https://${frontendEnvData.AUTHORITY_DOMAIN}/${frontendEnvData.B2C_TENANT_NAME}/${frontendEnvData.B2C_FLOW_NAME}`, // Choose SUSI as your default authority.
    knownAuthorities: [frontendEnvData.AUTHORITY_DOMAIN], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
      },
    },
  },
};
