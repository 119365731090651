import engineering from './engineering-insurance.png';
import fire from './fire-insurance.png';
import health from './health-and-accidental.png';
import liability from './liability-insurance.png';
import marine from './marine-insurance.png';
import motor from './motor-insurance.png';
import other from './other-insurances.png';
import travel from './travel-insurance.png';
import life from './life-insurance.png';
export const productCategoryIcons = {
  engineering,
  fire,
  health,
  liability,
  marine,
  motor,
  other,
  life,
  travel,
};
