import Image from 'next/image';
import React from 'react';

import StarSFillIcon from 'remixicon-react/StarSFillIcon';

export interface TestimonialProps {
  heading: string;
  image: any;
  name: string;
  profession: string;
  id?: number;
}

export const Testimonial = ({ heading, image, name, profession, id }: TestimonialProps) => {
  return (
    <div className="bg-surface-grey flex flex-col gap-4 rounded-lg p-8">
      <div className="flex gap-[15px]">
        <StarSFillIcon className="text-secondary" />
        <StarSFillIcon className="text-secondary" />
        <StarSFillIcon className="text-secondary" />
        <StarSFillIcon className="text-secondary" />
        <StarSFillIcon className="text-secondary" />
      </div>
      <div>
        <h1 className="text-title-5 max-w-[498px] font-bold">{heading}</h1>
      </div>
      <div className="flex justify-between">
        <div className="flex gap-2">
          <div>
            <Image src={image} alt="" width={45} height={45} />
          </div>
          <div className="flex flex-col">
            <h1 className="text-body-1 font-medium">{name}</h1>
            <p className="text-body-3">{profession}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
