import baggageInsurance from './baggage-insurance.png';
import groupTravelInsurance from './group-travel-insurance.png';
import multiTripInsurance from './multi-trip-insurance.png';
import other from './other.png';

import overseasTravelAnnualTrip from './overseas-travel-annual-trip.png';
import overseasTravelSingleTrip from './overseas-travel-single-trip.png';
import singleTripInsurance from './single-trip-insurance.png';
import studentTravelInsurance from './student-travel-insurance.png';

export const TravelInsuranceImages = {
  baggageInsurance,
  groupTravelInsurance,
  multiTripInsurance,
  overseasTravelAnnualTrip,
  overseasTravelSingleTrip,
  singleTripInsurance,
  studentTravelInsurance,
  other,
};
