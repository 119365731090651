import { Slider } from "libs/w3/ui/src/lib/about-posp";
import { Testimonial, TestimonialProps } from "..";
import { TestimonialIcons } from "../testimonial-icons";

export const TestimonialData: TestimonialProps[] = [
  {
    heading:
      'I truly appreciate the efficiency and integrity displayed by the team at PrishaPolicy, in addressing customer needs. This has been the driving force behind our partnership.',
    id: 1,
    image: TestimonialIcons.sanjiv,
    name: 'Sanjeev Mantri',
    profession: 'MD & CEO, ICICI Lombard',
  },
  {
    heading:
      'I’m sure Prishapolicy has become one of the industry runners with there top-notch services Always looking forward to work with them.',
    id: 2,
    image: TestimonialIcons.parag,
    name: 'Parag Ved',
    profession: 'CEO and Director, Libertry General Insurance',
  },
  {
    heading:
      'I am very happy working with PrishaPolicy their amazing services saves significant time everytime I require insurance related services. We trust on them.',
    id: 3,
    image: TestimonialIcons.sandeep,
    name: 'Sandeep Holani',
    profession: 'CFO, Hyatt Hotels',
  },
  {
    heading:
      'We find PrishaPolicy as one of the very few insurance brokers who not only possess 360° of expertise but their extensive experience in different types of business.',
    id: 4,
    image: TestimonialIcons.rajesh,
    name: 'Rajesh Jain',
    profession: 'Executive President, HFCL Limited',
  },
];

export const TestimonialSlider = () => {
  const testimonialSlideContent = [
    <div key="sanjiv">
      <Testimonial
        heading={
          'I truly appreciate the efficiency and integrity displayed by the team at PrishaPolicy, in addressing customer needs. This has been the driving force behind our partnership.'
        }
        image={TestimonialIcons.sanjiv}
        name={'Sanjeev Mantri'}
        profession={'MD & CEO, ICICI Lombard'}
        id={1}
      />
    </div>,
    <div key="parag">
      <Testimonial
        heading={
          'I’m sure Prishapolicy has become one of the industry runners with there top-notch services Always looking forward to work with them.'
        }
        image={TestimonialIcons.parag}
        name={'Parag Ved'}
        profession={'CEO and Director, Libertry General Insurance'}
        id={2}
      />
    </div>,
    <div key="Sandeep">
      <Testimonial
        heading={
          'I am very happy working with PrishaPolicy their amazing services saves significant time everytime I require insurance related services. We trust on them.'
        }
        image={TestimonialIcons.sandeep}
        name={'Sandeep Holani'}
        profession={'CEO, Channelplay Limited'}
        id={3}
      />
    </div>,
    <div key="rajesh">
      <Testimonial
        heading={
          'We find PrishaPolicy as one of the very few insurance brokers who not only possess 360° of expertise but their extensive experience in different types of business.'
        }
        image={TestimonialIcons.rajesh}
        name={'Rajesh Jain'}
        profession={'Executive President, HFCL Limited'}
        id={4}
      />
    </div>,
  ];
  return <Slider slides={testimonialSlideContent} className="-top-14 left-48"  />;
};

