import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
import Sheets from './Sheet.png'
import Shield from './Shield.png'
import mobileSheet from './mobileSheet.png'
import mobileShield from './mobileShield.png'
import Image from 'next/image'
import { useWindowWidth } from "@prishapolicy/shared/ui";

interface FeatureProductsCardProps {
    title: string;
    theme: string;
    imageUrl: any;
    link: string;
    description: string;
    buttonColor: string;
    mobileImage?: any;
}
export const FeatureProductsCardData: FeatureProductsCardProps[] = [
    {
        description: "A surety bond is a legally binding contract, used as an assurance that the issuer will pay any debts if the other party fails to do so",
        theme: "green-100",
        imageUrl: Sheets,
        link: "/surety-bond",
        title: "Surety Bond",
        buttonColor: "green-800",
        mobileImage: mobileSheet
    },
    {
        description: "Covers professionals and business owners against claims made by clients ...",
        theme: "yellow-200",
        imageUrl: Shield,
        link: "/insurance-for-doctors",
        title: "Professional Indemnity",
        buttonColor: "primary",
        mobileImage: mobileShield
    }
]

export const FeatureProductsCard = (props: FeatureProductsCardProps) => {
    const { isXl } = useWindowWidth();
    return (
        <div className={`bg-${props.theme} rounded-lg h-full xl:max-h-[277px] w-full`}>
            <div className='py-5 px-7 xl:pr-0 xl:pl-5  flex justify-between items-center w-full'>
                <div className='flex flex-col gap-8 xl:gap-4'>
                    <div className='flex flex-col gap-3'>
                        <h1 className='font-bold text-title-5'>{props.title}</h1>
                        <p className='font-normal text-body-1 xl:text-body-3 max-w-[300px] xl:max-w-[250px]'>{props.description}</p>
                    </div>
                    <div className='flex gap-3 items-center' >
                        <a href={props.link} className={`font-bold text-body-3 text-${props.buttonColor} hover:underline`}>Know more</a> <ArrowRightLineIcon className={`text-${props.buttonColor} w-5`} />
                    </div>
                </div>
                <div className="flex justify-end">
                    {isXl ?
                        <Image src={props.imageUrl} alt={props.title} width={152} height={186} className="object-cover" /> : <Image src={props.mobileImage} alt={props.title} width={152} height={186} className="" />}
                </div>
            </div>
        </div>
    )
}


export const ArticleSlider = ({ imageUrl, description, link }: {
    imageUrl: any;
    description: string;
    link: string;
}) => {
    return (
        <div>
            <div className='flex gap-3'>
                <Image src={imageUrl} alt='article' width={43} height={24} className='object-cover' />
                <div className='flex'>
                    <h1 className='font-medium text-body-1 max-w-[400px] truncate'>{description}</h1>
                    <a href={link} target="_blank" className='font-medium underline text-primary' rel="noreferrer">Read More</a>
                </div>
            </div>
        </div>
    )
}

export const FeatureCardSliderData = [
    <div>
        <FeatureProductsCard
            title={FeatureProductsCardData[0].title}
            theme={FeatureProductsCardData[0].theme}
            imageUrl={FeatureProductsCardData[0].imageUrl}
            link={FeatureProductsCardData[0].link}
            description={FeatureProductsCardData[0].description}
            buttonColor={FeatureProductsCardData[0].buttonColor}
            mobileImage={FeatureProductsCardData[0].mobileImage}
        />
    </div>,
    <div>
        <FeatureProductsCard
            title={FeatureProductsCardData[1].title}
            theme={FeatureProductsCardData[1].theme}
            imageUrl={FeatureProductsCardData[1].imageUrl}
            link={FeatureProductsCardData[1].link}
            description={FeatureProductsCardData[1].description}
            buttonColor={FeatureProductsCardData[1].buttonColor}
            mobileImage={FeatureProductsCardData[1].mobileImage}
        />
    </div>,
]
