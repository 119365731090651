import Fuse from 'fuse.js';

export const useFuzzySearch = <T,>(data: readonly T[], searchTerm: string, augmenter?: (row: T) => object) => {
  if (!searchTerm) return data;
  const finalAugmenter = augmenter ? augmenter : (row: T) => row;

  const input = data.map((row, idx) => ({
    data: JSON.stringify({
      ...finalAugmenter(row),
      __fuseIdx: idx,
    }),
  }));
  const fuse = new Fuse(input, {
    keys: ['data'],
    minMatchCharLength: 0,
    threshold: 1,
    distance: 1000,
  });
  const results = fuse.search(searchTerm).map((t) => data[JSON.parse(t.item.data).__fuseIdx] as T);
  return results;
};
