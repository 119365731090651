import { Badge, BadgeColor } from '@prishapolicy/shared/ui';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import leapRight from './leapRight.png';
import leap from './leap.png';
import leapLogo from './leapLogo.png';
import advantageLogo from './advantageLogo.png';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

export interface PlatformLoginCardProps {
  platformLogo: any;
  platformName: string;
  platformDescription: string;
  badgeName: string;
  badgeTheme: BadgeColor;
  link: string;
}

export const PlatformData = [
  {
    platformLogo: leapLogo,
    platformName: 'Leap',
    platformDescription: 'Leap is our POSP software to which helps.....',
    badgeName: 'POSP',
    badgeTheme: 'blue',
    link: 'https://leap.prishapolicy.com/onboarding',
  },
  {
    platformLogo: advantageLogo,
    platformName: 'Advantage',
    platformDescription: 'Client Portal helps navigating through corporate policies.',
    badgeName: 'Client Portal',
    badgeTheme: 'yellow',
    link: 'https://advantage.prishapolicy.com/login',
  },
];

interface LoginProps {
  setClose: React.Dispatch<React.SetStateAction<boolean>>;
  isLoginShow: boolean;
}

export const Login = ({ setClose, isLoginShow }: LoginProps) => {
  return (
    <div className="mt-3 flex w-[600px] flex-col gap-6 rounded-xl bg-white py-9 px-6 shadow-xl">
      <div className="flex items-center justify-between">
        <h1 className="text-title-6 font-bold">Our Digital Suite</h1>
        <CloseLineIcon onClick={() => setClose(!isLoginShow)} className="cursor-pointer" />
      </div>
      <div className="flex justify-between rounded-2xl border border-blue-400 bg-blue-100 py-6 px-5">
        <div className="flex flex-col justify-between">
          <div>
            <h3 className="text-primary text-body-3 font-medium">Be Your Own Boss</h3>
            <h1 className="text-primary text-title-4 mb-3 font-bold">Become a POSP</h1>
          </div>
          <a href={'/about/about-our-posps'} className="text-primary text-body-3 font-medium">
            Learn more
          </a>
        </div>
        <div className="relative flex items-center">
          <Image src={leapRight} alt="Leap" className="leapRight-image" />
          <Image src={leap} alt="Leap" className="leap-image absolute left-4  -translate-x-1/2 " />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {PlatformData.map((e, idx) => (
          <div key={idx}>
            <PlatformLoginCard
              platformLogo={e.platformLogo}
              platformName={e.platformName}
              platformDescription={e.platformDescription}
              badgeName={e.badgeName}
              badgeTheme={e.badgeTheme as BadgeColor}
              link={e.link}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const PlatformLoginCard = ({
  platformDescription,
  platformLogo,
  platformName,
  badgeName,
  badgeTheme,
  link,
}: PlatformLoginCardProps) => {
  return (
    <Link href={link}>
      <div className="border-grey-200 flex w-full cursor-pointer gap-6 rounded-xl border p-5">
        <div className="top-0">
          <Image src={platformLogo} alt={platformName} />
        </div>
        <div className="flex w-full flex-col gap-2">
          <div className="flex justify-between">
            <h1 className="text-title-6 font-bold">{platformName}</h1>
            <Badge text={badgeName} variant="lozenge" theme={badgeTheme} />
          </div>
          <p className="text-title-7 w-full font-normal">{platformDescription}</p>
        </div>
      </div>
    </Link>
  );
};
