import Image from 'next/image';
import React from 'react';
import leapRight from '../../expanded-header/nav-login/leapRight.png';
import leap from '../../expanded-header/nav-login/leap.png';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { PlatformData, PlatformLoginCard } from '../../expanded-header/nav-login';
import { BadgeColor, useWindowWidth } from '@prishapolicy/shared/ui';

interface MobileLoginDrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MobileLoginDrawer = ({ isDrawerOpen, setIsDrawerOpen }: MobileLoginDrawerProps) => {
  const { isXl } = useWindowWidth();
  return (
    <div>
      {isDrawerOpen && !isXl && (
        <div className="absolute bottom-0 z-[3000] h-[60vh] w-full rounded-t-lg bg-white">
          <div className="flex flex-col gap-3">
            <div className="border-grey-200 flex items-center justify-between border-b p-4">
              <h1 className="text-primary text-title-6 font-bold ">Our Digital Suite</h1>
              <CloseLineIcon onClick={() => setIsDrawerOpen(!isDrawerOpen)} className="cursor-pointer" />
            </div>
            <div className="flex flex-col gap-3 overflow-auto h-[50vh]  p-4">
              <div className="flex justify-between rounded-2xl border border-blue-400 bg-blue-100 py-6 px-5">
                <div className="flex flex-col justify-between">
                  <div>
                    <h3 className="text-primary text-body-3 font-medium">Be Your Own Boss</h3>
                    <h1 className="text-primary text-title-4 mb-3 font-bold">Become a POSP</h1>
                  </div>
                  <a href={'/about/about-our-posps'} className="text-primary text-body-3 font-medium">
                    Learn more
                  </a>
                </div>
                <div className="relative flex items-center">
                  <Image src={leapRight} alt="Leap" className="leapRight-image" />
                  <Image src={leap} alt="Leap" className="leap-image absolute left-4  -translate-x-1/2 " />
                </div>
              </div>
              <div className="flex flex-col gap-2 mb-3">
                {PlatformData.map((e, idx) => (
                  <div key={idx}>
                    <PlatformLoginCard
                      platformLogo={e.platformLogo}
                      platformName={e.platformName}
                      platformDescription={e.platformDescription}
                      badgeName={e.badgeName}
                      badgeTheme={e.badgeTheme as BadgeColor}
                      link={e.link}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
