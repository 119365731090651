import AddLineIcon from 'remixicon-react/AddLineIcon';
import AlertIcon from 'remixicon-react/AlertFillIcon';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import CheckIcon from 'remixicon-react/CheckFillIcon';
import CloseIcon from 'remixicon-react/CloseLineIcon';
import DeleteBin5LineIcon from 'remixicon-react/DeleteBin5LineIcon';
import LockPasswordFillIcon from 'remixicon-react/LockPasswordFillIcon';
import LogoutCircleRLineIcon from 'remixicon-react/LogoutCircleRLineIcon';
import MailFillIcon from 'remixicon-react/MailFillIcon';
import MailLineIcon from 'remixicon-react/MailLineIcon';
import PhoneFillIcon from 'remixicon-react/PhoneFillIcon';
import RestartLineIcon from 'remixicon-react/RestartLineIcon';
import SearchFillIcon from 'remixicon-react/SearchLineIcon';
import Translate from 'remixicon-react/TranslateIcon';
import CertificateIcon from 'remixicon-react/ProfileLineIcon';
import PencilFillIcon from 'remixicon-react/PencilFillIcon';
import DownloadLineIcon from 'remixicon-react/Download2LineIcon';
import DownloadFillIcon from 'remixicon-react/DownloadFillIcon';
import FilterFillIcon from 'remixicon-react/FilterFillIcon';
import EqualizerFillIcon from 'remixicon-react/EqualizerFillIcon';
import WhatsappLineIcon from 'remixicon-react/WhatsappLineIcon';
import Save2FillIcon from 'remixicon-react/Save2FillIcon';
import More2FillIcon from 'remixicon-react/More2FillIcon';
import FileDownloadLine from 'remixicon-react/FileDownloadLineIcon';
import GitBranchLine from 'remixicon-react/GitBranchLineIcon';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArchiveFillIcon from 'remixicon-react/ArchiveFillIcon';
import SendPlane2FillIcon from 'remixicon-react/SendPlane2FillIcon';
import VideoChatLineIcon from 'remixicon-react/VideoChatLineIcon';
import MapPinUserLineIcon from 'remixicon-react/MapPinUserLineIcon';

export const buttonIconsMap = {
  tick: CheckIcon,
  pencil: PencilFillIcon,
  cross: CloseIcon,
  alert: AlertIcon,
  password: LockPasswordFillIcon,
  delete: DeleteBin5LineIcon,
  phone: PhoneFillIcon,
  mail: MailFillIcon,
  mailLine: MailLineIcon,
  refresh: RestartLineIcon,
  search: SearchFillIcon,
  add: AddLineIcon,
  logout: LogoutCircleRLineIcon,
  translate: Translate,
  arrowLeft: ArrowLeftSLineIcon,
  arrowRight: ArrowRightSLineIcon,
  arrowDown: ArrowDownSLineIcon,
  download: DownloadFillIcon,
  filter: FilterFillIcon,
  configure: EqualizerFillIcon,
  certificate: CertificateIcon,
  downloadLine: DownloadLineIcon,
  whatsApp: WhatsappLineIcon,
  save: Save2FillIcon,
  more: More2FillIcon,
  fileDownloadLine: FileDownloadLine,
  gitBranchLine: GitBranchLine,
  archive: ArchiveFillIcon,
  edit: SendPlane2FillIcon,
  videoCall: VideoChatLineIcon,
  mapPinUser: MapPinUserLineIcon,
};
