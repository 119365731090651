export const stagingPublicADB2CConfig = {
  AUTHORITY_DOMAIN: 'prishainsurancebrokers.b2clogin.com',
  AD_B2C_TENANT_ID: '690a20d1-40b9-4f07-8c74-a3cfb93f4d10',
  B2C_TENANT_NAME: 'prishainsurancebrokers.onmicrosoft.com',
  VANTAGE_CLIENT_ID: 'df31faec-9c61-4434-9a35-600d6781b6dc',
  BACKEND_CORE_CLIENT_ID: '7424bd9e-4b70-4e9f-a8c1-06436070aec8',
  B2C_FLOW_NAME: 'B2C_1_otp-user-flow',
};

export const prodPublicADB2CConfig = {
  AUTHORITY_DOMAIN: 'prishainsurancebrokersprod.b2clogin.com',
  AD_B2C_TENANT_ID: '72dc7a63-4c76-48c8-8a39-3037b4936211',
  B2C_TENANT_NAME: 'prishainsurancebrokersprod.onmicrosoft.com',
  VANTAGE_CLIENT_ID: '73ab9c30-d1a3-415c-adbc-e517a89017d5',
  BACKEND_CORE_CLIENT_ID: '50c51216-b7dd-4dcc-98ef-eff35ffbd5b8',
  B2C_FLOW_NAME: 'B2C_1_otp-user-flow',
};

export const authScopesWithoutPrefix = ['Core.Access'];
