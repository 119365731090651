/* eslint-disable @next/next/no-sync-scripts */
import { getUrlsParams } from '@prishapolicy/shared/auth-client';
import { BackendCoreNoAuthTrpcProvider } from '@prishapolicy/shared/trpc';
import { CustomToastContainer, MaterialThemeContext } from '@prishapolicy/shared/ui';
import { Footer, Header } from '@prishapolicy/w3/ui';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import './styles.scss';
const NoSSRTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false,
});
function CustomApp({ Component, pageProps }: AppProps) {
  const [environment, setEnvironment] = useState<getUrlsParams['env']>();
  const path = useRouter().asPath;
  useEffect(() => {
    const hostname = window.location.hostname;
    if (!hostname.includes('localhost')) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      console.log = () => { };
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      console.error = () => { };
    }
    setEnvironment(
      hostname.includes('taketheleap.co.in') ? 'STAGING' : hostname.includes('prishapolicy') ? 'PROD' : 'DEV',
    );
  }, []);

  return (
    <>
      <BackendCoreNoAuthTrpcProvider env={environment}>
        <MaterialThemeContext>
          <DefaultSeo
            title="PrishaPolicy"
            canonical={`https://www.prishapolicy.com${path}`}
            openGraph={{
              siteName: 'PrishaPolicy',
              type: 'website',
              locale: 'en_IN',
              url: 'https://www.prishapolicy.com',
            }}
          />

          <Header />
          <main
            className="app"
            onCopy={(event) => {
              event.preventDefault();
            }}
          >

            <Component {...pageProps} />
          </main>
          <Footer />
          <NoSSRTooltip />
          <CustomToastContainer />
        </MaterialThemeContext>
      </BackendCoreNoAuthTrpcProvider>
      {/* <Script id="chatbot" strategy="afterInteractive">
        {`
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/63cf6612c2f1ac1e202f419a/1gnh2dek7';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();
        `}
      </Script> */}
      <Script
        type="text/javascript"
        src="https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js"
        id="aisensy-wa-widget"
        widget-id="TwbJFR"
        strategy="afterInteractive"
      />
    </>
  );
}

export default CustomApp;
