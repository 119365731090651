import completeHealthInsurance from './complete-health-insurance.png';
import criticaIillnessPolicy from './critical-illness-policy.png';
import groupHealthInsurance from './group-health-insurance.png';
import groupPersonalAccident from './group-personal-accident.png';
import groupTermLifeInsurance from './group-term-life-insurance.png';
import other from './other.png';
import personalAccidentInsurance from './personal-accident-insurance.png';


export const HealthInsuranceImages = {
  completeHealthInsurance,
  criticaIillnessPolicy,
  groupHealthInsurance,
  groupPersonalAccident,
  groupTermLifeInsurance,
  other,
  personalAccidentInsurance,
};

