import commercialCyberRisk from './commercial-cyber-risk.png'
import generalLiability from './commercial-general-liability.png'
import employmentPracticesLiability from './employment-practices-liability.png'
import directoresOfficers from './directors-and-officers.png'
import productLiabilityInsurance from './product-liability-insurance.png'
import professionalIndemnity from './professional-indemnity.png'
import publicliabilityInsurance from './public-liability-insurance.png'
import tradeCreditInsurance from './trade-credit-insurance.png'
import workmenCompensation from './workmen-compensation.png'

export const LiabilityCategoryIcons = {
    commercialCyberRisk,
    generalLiability,
    employmentPracticesLiability,
    directoresOfficers,
    productLiabilityInsurance,
    professionalIndemnity,
    publicliabilityInsurance,
    tradeCreditInsurance,
    workmenCompensation
};