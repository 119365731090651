import { StaticImageData } from 'next/image';
import React from 'react';
import Image from 'next/image';

export interface PospCardprops {
  bgColor: string;
  insuranceName: string;
  image: StaticImageData;
  preMoney: number;
  commissionMoney: number;
}

export const PospCard = ({ bgColor, insuranceName, image, preMoney, commissionMoney }: PospCardprops) => {
  return (
    <div className={`${bgColor} rounded-[20px] pb-5 shadow-md`}>
      <div className="grid-cols-0 container grid py-10  xl:grid-cols-2 ">
        <div className="text-white">
          <div>
            <h2 className="text-body-1 font-normal">This Month Payout</h2>
            <h1 className="text-title-5 mb-0 font-bold xl:mb-[50px]">{insuranceName}</h1>
          </div>

          <div className="flex justify-center py-[25px] xl:hidden xl:py-0">
            <Image src={image} alt="posp-image" />
          </div>

          <div>
            <h1 className="text-body-1 font-semibold">Premium Collected: ₹{preMoney} </h1>
            <h1 className="text-title-5 font-bold">Commission Earned: ₹{commissionMoney}</h1>
          </div>
        </div>
        <div className="hidden xl:block">
          <Image src={image} alt="posp-image" />
        </div>
      </div>
    </div>
  );
};
