import fleet from './fleet.png'
import bike from './bike.png';
import car from './car.png';
import commercial from './commercial.png';
import workmen from './workmen.png';

export const motorCategoryIcons = {
    fleet,
    bike,
    car,
    commercial,
    workmen
};