import blogs from './blogs.png';
import calculators from './calculators.png';
import checklists from './checklists.png';
import glossary from './glossary.png';
import resources from './resources.png';
import academy from './academy.png';

export default {
  blogs,
  calculators,
  checklists,
  glossary,
  resources,
  academy,
};
