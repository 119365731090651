import { OptionalAuthRouter } from '@prishapolicy/backend-core/trpc';
import { getFrontendEnvData, getUrlsParams } from '@prishapolicy/shared/auth-client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { PropsWithChildren, useState } from 'react';
import { reactQueryClientConfig } from '../react-query';
import superjson from 'superjson';
import { inferRouterOutputs } from '@trpc/server';

export const trpcNoAuth = createTRPCReact<OptionalAuthRouter>();
export type TrpcNoAuthRouterOutputs = inferRouterOutputs<OptionalAuthRouter>;
export const BackendCoreNoAuthTrpcProvider = (props: PropsWithChildren<getUrlsParams>) => {
  const [queryClient] = useState(() => new QueryClient(reactQueryClientConfig));
  const backendUrl = getFrontendEnvData(props).urls.backendCore;
  const trpcClient = trpcNoAuth.createClient({
    transformer: superjson,
    links: [
      httpBatchLink({
        url: `${backendUrl}/trpc-no-auth`,
      }),
    ],
  });
  return (
    <trpcNoAuth.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
    </trpcNoAuth.Provider>
  );
};
