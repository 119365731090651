import { lazy, ReactNode } from 'react';
import { toast } from 'react-toastify';
import { RemixiconReactIconComponentType } from 'remixicon-react';
import CheckboxCircleFillIcon from 'remixicon-react/CheckboxCircleFillIcon';
import CloseCircleFillIcon from 'remixicon-react/CloseCircleFillIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import ErrorWarningFill from 'remixicon-react/ErrorWarningFillIcon';
import InformationFillIcon from 'remixicon-react/InformationFillIcon';
import Button from '../button';

export type ToastVariant = 'primary' | 'success' | 'danger' | 'warning';

export type ToastProps = {
  variant?: ToastVariant;
  title?: string;
  description?: ReactNode;
  buttonText?: string;
  showButton?: boolean;
  showClose?: boolean;
  onButtonClick?: () => void;
};

const toastIconMap: {
  [key in ToastVariant]: RemixiconReactIconComponentType;
} = {
  primary: InformationFillIcon,
  success: CheckboxCircleFillIcon,
  danger: CloseCircleFillIcon,
  warning: ErrorWarningFill,
};

const titleTextColorMap: {
  [key in ToastVariant]: string;
} = {
  primary: 'primary',
  success: 'green-800',
  danger: 'red-800',
  warning: 'yellow-900',
};

const bgColorMap: {
  [key in ToastVariant]: string;
} = {
  primary: 'blue',
  success: 'green',
  danger: 'red',
  warning: 'yellow',
};

const iconColorMap: {
  [key in ToastVariant]: string;
} = {
  primary: '#27378C',
  success: '#4EC33D',
  danger: '#F64B3C',
  warning: '#FFB726',
};

const descColorMap: {
  [key in ToastVariant]: string;
} = {
  primary: 'primary',
  success: 'green-600',
  danger: 'red-600',
  warning: 'yellow-800',
};

const borderColorMap: {
  [key in ToastVariant]: string;
} = {
  primary: 'primary',
  success: 'green-400',
  danger: 'red-400',
  warning: 'yellow-400',
};

export const showToast = (props: ToastProps) => {
  toast(<ToastTemplate {...props} />);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const showErrorToast = (e: any) => {
  showToast({
    variant: 'danger',
    title: `Error ${e.shape.data.code}`,
    description: `${e.shape.message}`,
  });
};
export const ToastTemplate = ({ showClose = true, ...props }: ToastProps) => {
  const title = props.title;
  const description = props.description;
  const showButton = props.showButton;
  const variant = props.variant ? props.variant : 'primary';
  const bgColor = bgColorMap[variant];
  const titleTextColor = titleTextColorMap[variant];
  const Icon = toastIconMap[variant];
  const iconColor = iconColorMap[variant];
  const descriptionColor = descColorMap[variant];
  const borderColor = borderColorMap[variant];
  return (
    <div
      className={`flex  flex-col gap-2 rounded-md lg:mx-0 bg-${bgColor}-100 border-1.5 w-full px-5 py-5 shadow-md border-${borderColor}`}
    >
      <div className="mx-2 flex justify-between">
        <div className="flex gap-3">
          <div>
            <Icon color={iconColor} />
          </div>
          <div className={`text-${titleTextColor} self-center font-medium`}>{title}</div>
        </div>
        <div className="flex items-start gap-3">
          {showButton && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.onButtonClick && props.onButtonClick();
              }}
              theme={variant}
              text={'Go To Page'}
              variant="primary"
              size="small"
            />
          )}
          {showClose && (
            <div className="cursor-pointer">
              <CloseLineIcon color={iconColor} />
            </div>
          )}
        </div>
      </div>
      {description && (
        <div className="mx-2 mt-1 flex pl-9">
          <div className={`text-${descriptionColor} text-body-3`}>{description}</div>
        </div>
      )}
    </div>
  );
};

export const CustomToastContainer = lazy(() => import('./custom-container'));
