import AvatarUserIcon from 'remixicon-react/User2FillIcon';
import ReactTooltip from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

export type AvatarFill = 'image' | 'icon' | 'text';
export type AvatarVariant = 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge';

export type AvatarShape = 'square' | 'circle';

export type AvatarProps = {
  text?: string;
  fillType?: AvatarFill;
  variant?: AvatarVariant;
  shape?: AvatarShape;
  border?: boolean;
  className?: string;
  imageURL?: string;
};

export const avatarDimensionMap: {
  [key in AvatarVariant]: string;
} = {
  extraSmall: '12px',
  small: '16px',
  medium: '24px',
  large: '32px',
  extraLarge: '44px',
};

// const paddingMap: {
//   [key in AvatarVariant]: string;
// } = {
//   extraSmall: '4px',
//   small: '4.5px',
//   medium: '7px',
//   large: '9px',
//   extraLarge: '12px',
// };

const borderRadiusMap: {
  [key in AvatarVariant]: string;
} = {
  extraSmall: 'small',
  small: 'small',
  medium: 'small',
  large: 'medium',
  extraLarge: 'full',
};

const borderSizeMap: {
  [key in AvatarVariant]: string;
} = {
  extraSmall: '1px',
  small: '1px',
  medium: '1.5px',
  large: '4px',
  extraLarge: '4px',
};

const fontSizeMap: {
  [key in AvatarVariant]: string;
} = {
  extraSmall: '4px',
  small: '6px',
  medium: '8px',
  large: '12px',
  extraLarge: '16px',
};

export const Avatar = (props: AvatarProps) => {
  const fillType = props.fillType || 'image';
  const variant = props.variant || 'large';
  const shape = props.shape || 'circle';
  const imageURL = props.imageURL;
  const border = props.border;
  const avatarDimension = avatarDimensionMap[variant];
  // const padding = paddingMap[variant];
  const borderRadius = shape === 'circle' ? 'full' : borderRadiusMap[variant];
  const borderSize = borderSizeMap[variant];
  const fontSize = fontSizeMap[variant];
  const AvatarBgStyles = {
    // padding,
    borderWidth: borderSize,
  };
  const className = props.className;
  return (
    <div
      className={twMerge(
        ` ${
          fillType !== 'image' &&
          `text-primary grid w-fit place-items-center rounded-full bg-blue-100 rounded-${borderRadius} ${
            shape === 'circle' ? 'rounded-full' : 'rounded-medium'
          } border-white ${border && 'border-surface'}`
        } ${className}`,
      )}
      style={fillType !== 'image' ? AvatarBgStyles : undefined}
    >
      {fillType === 'icon' ? (
        <AvatarUserIcon size={avatarDimension} />
      ) : (
        <div>
          {fillType === 'text' ? (
            <>
              <div
                className="grid place-items-center font-bold text-black"
                style={{
                  fontSize,
                  height: avatarDimension,
                  width: avatarDimension,
                }}
                data-tip
                data-for={`registerTip_${props.text}`}
              >
                {props.text!.split(' ')[1] !== '+' ? props.text!.split(' ')[0][0] : props.text!.split(' ')[0]}
                {props.text!.split(' ').length > 1 ? props.text!.split(' ')[1][0] : ''}
              </div>
              <ReactTooltip id={`registerTip_${props.text}`} place="top" effect="solid">
                {props.text}
              </ReactTooltip>
            </>
          ) : (
            <img
              src={imageURL}
              alt="AvatarIcon"
              height={avatarDimension}
              width={avatarDimension}
              className={`${shape === 'circle' ? 'rounded-full' : 'rounded-medium'} ${
                border ? 'border-surface border-2' : ''
              }`}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Avatar;
