import motor from './motor.png';
import engineering from './engineering.png';
import fire from './fire.png';
import health from './health.png';
import liability from './liabilities.png';
import life from './life.png';
import marine from './marine.png';
import other from './other.png';
import travel from './travel.png';

export const MobileInsuranceCardImages = {
  motor,
  engineering,
  fire,
  health,
  liability,
  life,
  marine,
  other,
  travel,
};
