import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon';
import insuranceDetariffication   from './insuranceDatarrification.png'
import nineReasons   from './nineReasons.png'


export const BlogsData = [
  {
    heading: 'Insurance Detarrification',
    blogPoster: insuranceDetariffication,
    description: 'The Indian insurance industry is shortly set to undergo a major...',
    link: '/resources/blog/de-tariffing',
  },
  {
    heading: 'Reasons Why Motor Insurance gets rejected',
    blogPoster: nineReasons,
    description: 'Owning a motor vehicle comes with the responsibility of having...',
    link: '/resources/blog/nine-reasons-why-your-motor-claim-is-rejected/',
  },
];

export const ResourcesBlogs = () => {
  return (
    <div className="bg-surface-grey flex flex-col gap-3 rounded-lg p-8">
      <h1 className="text-body-1 text-primary font-bold pl-3">Latest Blogs</h1>
      {BlogsData.map((e,idx) => (
        <div key={idx}>
          <NavCardTemplate heading={e.heading} Poster={e.blogPoster} description={e.description} link={e.link} />
        </div>
      ))}
      <Link href={'/resources/blog'} className="text-body-1 text-primary flex gap-3 font-bold pl-3">
        <span>Explore Blogs</span>
        <ArrowRightLineIcon />
      </Link>
    </div>
  );
};

export interface NavCardTemplateProps {
  heading: string;
  Poster: any;
  description: string;
  link: string;
}

export const NavCardTemplate = ({heading,Poster,description,link}: NavCardTemplateProps) => {
  return (
    <Link href={link}>
      <div className="flex cursor-pointer gap-3 p-3">
        <div className="flex items-center">
          <Image src={Poster} alt={heading} width={150} height={81} style={{ objectFit: 'contain' }} />
        </div>
        <div className="flex flex-col gap-1">
          <h1 className="text-title-7 max-w-[394px] truncate font-bold">{heading}</h1>
          <p className="text-grey-700 text-body-1">{description}</p>
        </div>
      </div>
    </Link>
  );
};
