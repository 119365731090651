import careers from './careers.png';
import posps from './posps.png';
import press from './press.png';
import privacy from './privacy.png';
import terms from './terms.png';
import who from './who.png';

export default {
  careers,
  posps,
  press,
  privacy,
  terms,
  who,
};
