import { Button } from '@prishapolicy/shared/ui';
import { useRouter } from 'next/router';

export interface TrackYourPurchaseProps {
  primaryButtonText?: string;
}

export const TrackYourPurchase = ({ primaryButtonText }: TrackYourPurchaseProps) => {
  const router = useRouter();
  return (
    <div className="">
      <div className="flex  gap-2 ">
        <div className="bg-transparent">
          <Button
            text="Track Your Purchase"
            variant="primary"
            theme="white"
            size="large"
            rightIcon='configure'
            className="border-primary text-primary border font-medium "
            onClick={() => router.push('/track-policy-status')}
          />
        </div>
        <Button
          text={primaryButtonText}
          size="large"
          rightIcon="arrowRight"
          className="font-medium"
          onClick={() => { router.push('https://buy.prishapolicy.com/') }}
        />
      </div>
    </div>
  );
};
