import { MobileInsuranceCardImages } from '../../../Mobile-Nav/mobile-nav-insurance-image';
import { productCategoryIcons } from '../icons';
import { EngineeringInsuranceImages } from '../insurance-icons/engineering-insurance';
import { FireInsuranceImages } from '../insurance-icons/fire-insurance';
import { HealthInsuranceImages } from '../insurance-icons/health';
import { LiabilityCategoryIcons } from '../insurance-icons/liability-insurance';
import { LifeInsuranceImages } from '../insurance-icons/life';
import { MarineInsuranceImages } from '../insurance-icons/marine';
import { motorCategoryIcons } from '../insurance-icons/motor-insurance-icons';
import { OtherhInsuranceImages } from '../insurance-icons/other';
import { TravelInsuranceImages } from '../insurance-icons/travel';
import { InsuranceCardImages } from '../insurance-images';
import { NavProductType } from '../nav-products';

export const productsList: NavProductType[] = [
  {
    name: 'Motor Insurance',
    activeColor: 'red-400',
    description:
      'Motor Insurance policies are designed to protect you from unforeseen expenses concerning your vehicle.',
    icon: productCategoryIcons.motor,
    theme: 'red',
    cardImages: InsuranceCardImages.motor,
    cardLink: '/products/motor-insurance/',
    mobileCardImage: MobileInsuranceCardImages.motor,
    products: [
      {
        name: 'Commercial Vehicle Insurance',
        productIcon: motorCategoryIcons.commercial,
      },
      {
        name: 'Fleet Insurance',
        productIcon: motorCategoryIcons.fleet,
      },
      { name: 'Private Car Insurance', productIcon: motorCategoryIcons.car },
      { name: 'Third Party Insurance', productIcon: motorCategoryIcons.workmen },
      { name: 'Two Wheeler Insurance', productIcon: motorCategoryIcons.bike },
    ],
  },
  {
    name: 'Health And Accidental',
    activeColor: 'green-300',
    theme: 'green',
    cardImages: InsuranceCardImages.health,
    description:
      'Health Insurance policies are designed to protect you from unforeseen expenses concerning your health.',
    icon: productCategoryIcons.health,
    mobileCardImage: MobileInsuranceCardImages.health,
    cardLink: '/products/health-and-accidental/',
    products: [
      // {
      // name: 'Personal Health',
      // },
      { name: 'Complete Health Insurance', productIcon: HealthInsuranceImages.completeHealthInsurance },
      { name: 'Critical Illness Policy', productIcon: HealthInsuranceImages.criticaIillnessPolicy },
      { name: 'Group Health Insurance', productIcon: HealthInsuranceImages.groupHealthInsurance },
      { name: 'Group Personal Accident', productIcon: HealthInsuranceImages.groupPersonalAccident },
      { name: 'Personal Accident Insurance', productIcon: HealthInsuranceImages.personalAccidentInsurance },
      // { name: 'Group Term Life (GTL)', productIcon: HealthInsuranceImages.groupTermLifeInsurance },
    ],
  },
  {
    name: 'Travel Insurance',
    activeColor: 'blue-300',
    theme: 'blue',
    cardImages: InsuranceCardImages.travel,
    cardLink: '/products/travel-insurance/',
    mobileCardImage: MobileInsuranceCardImages.travel,
    description:
      'Travel Insurance policies are designed to protect you from unforeseen expenses concerning your vehicle.',
    icon: productCategoryIcons.travel,
    products: [
      {
        name: 'Baggage Insurance',
        productIcon: TravelInsuranceImages.baggageInsurance,
      },
      { name: 'Group Travel Insurance', productIcon: TravelInsuranceImages.groupTravelInsurance },
      { name: 'Multi Trip Insurance', productIcon: TravelInsuranceImages.multiTripInsurance },
      {
        name: 'Single Trip Insurance',
        productIcon: TravelInsuranceImages.singleTripInsurance,
      },
      { name: 'Student Travel Insurance', productIcon: TravelInsuranceImages.studentTravelInsurance },
    ],
  },
  {
    name: 'Life Insurance',
    activeColor: 'purple-200',
    theme: 'teal',
    cardImages: InsuranceCardImages.life,
    mobileCardImage: MobileInsuranceCardImages.life,
    cardLink: '/products/life-insurance/',
    description:
      'Life Insurance policies are designed to protect you from unforeseen circumstances concerning you and your loved ones',
    icon: productCategoryIcons.life,
    products: [
      {
        name: 'Term Life',
        productIcon: LifeInsuranceImages.term,
      },
      { name: 'Whole Life', productIcon: LifeInsuranceImages.wholeLife },
      { name: 'Endowment', productIcon: LifeInsuranceImages.endowment },
      { name: 'ULIP', productIcon: LifeInsuranceImages.uulp },
    ],
  },
  {
    name: 'Fire Insurance',
    activeColor: 'red-400',
    theme: 'red',
    cardLink: '/products/fire-insurance/',
    mobileCardImage: MobileInsuranceCardImages.fire,
    cardImages: InsuranceCardImages.fire,
    description: 'Fire Insurance policies are designed to protect you from unforeseen expenses concerning your .',
    icon: productCategoryIcons.fire,
    products: [
      {
        name: 'Bharat Griha Raksha',
        productIcon: FireInsuranceImages.Griha,
      },
      { name: 'Bharat Laghu Udyam', productIcon: FireInsuranceImages.bharatLaghuUdyam },
      { name: 'Bharat Sookshma Udyam', productIcon: FireInsuranceImages.bharatSookshmaUdyam },
      { name: 'Fire Loss of Profit', productIcon: FireInsuranceImages.FireLossProfit },
      { name: 'Griha', productIcon: FireInsuranceImages.Griha },
      { name: 'Standard Fire And Special Perils', productIcon: FireInsuranceImages.StandardFireAndSpecial },
    ],
  },

  {
    name: 'Engineering Insurance',
    activeColor: 'teal-300',
    theme: 'teal',
    cardLink: '/products/engineering-insurance/',
    mobileCardImage: MobileInsuranceCardImages.engineering,
    cardImages: InsuranceCardImages.engineering,
    description:
      'Engineering Insurance policies are designed to protect you from unforeseen expenses concerning your .',
    icon: productCategoryIcons.engineering,
    products: [
      { name: 'Advanced Loss of Profit', productIcon: EngineeringInsuranceImages.LossAndProfit },
      {
        name: 'Boiler and Pressure Plant Machinery',
        productIcon: EngineeringInsuranceImages.ContractorsPlant,
      },
      { name: 'Contractors All Risk', productIcon: EngineeringInsuranceImages.ContractorsAllRisk },
      { name: 'Contractors Plant And Machinery', productIcon: EngineeringInsuranceImages.contractorsPlantAndMachinery },
      {
        name: 'Electronic Equipment Insurance',
        productIcon: EngineeringInsuranceImages.Electronic,
      },
      {
        name: 'Erection All Risk',
        productIcon: EngineeringInsuranceImages.erection,
      },
      { name: 'Industrial All Risk', productIcon: EngineeringInsuranceImages.ContractorsAllRisk },
      { name: 'Machinery Breakdown', productIcon: EngineeringInsuranceImages.machineryBreakdown },
      { name: 'Machinery Loss of Profit', productIcon: EngineeringInsuranceImages.machineryLossAndProfit },
    ],
  },

  {
    name: 'Marine Insurance',
    activeColor: 'blue-300',
    theme: 'blue',
    cardLink: '/products/fire-insurance/',
    cardImages: InsuranceCardImages.marine,
    mobileCardImage: MobileInsuranceCardImages.marine,
    description:
      'Marine Insurance policies are designed to protect you from unforeseen expenses concerning your vehicle.',
    icon: productCategoryIcons.marine,
    products: [
      { name: 'Annual Sales Turnover', productIcon: MarineInsuranceImages.annualSalesTurnover },
      {
        name: 'Open Cover',
        productIcon: MarineInsuranceImages.openCover,
      },
      {
        name: 'Single Transit',
        productIcon: MarineInsuranceImages.singleTransit,
      },
    ],
  },
  {
    name: 'Liability Insurance',
    description: 'Liability Insurance policies are designed to protect you from unforeseen expenses concerning your .',
    icon: productCategoryIcons.liability,
    activeColor: 'yellow-300',
    theme: 'yellow',
    cardLink: '/products/liability-insurance/',
    mobileCardImage: MobileInsuranceCardImages.liability,
    cardImages: InsuranceCardImages.liability,
    products: [
      { name: 'Professional Indemnity Insurance', productIcon: LiabilityCategoryIcons.professionalIndemnity },
      { name: 'Cyber Insurance', productIcon: LiabilityCategoryIcons.workmenCompensation },
      // { name: 'Commercial Cyber Risk', productIcon: LiabilityCategoryIcons.commercialCyberRisk },
      {
        name: 'Commercial General Liability',
        productIcon: LiabilityCategoryIcons.generalLiability,
      },
      { name: 'Directors And Officers', productIcon: LiabilityCategoryIcons.directoresOfficers },
      { name: 'Employment Practices Liability', productIcon: LiabilityCategoryIcons.employmentPracticesLiability },
      { name: 'Product Liability Insurance', productIcon: LiabilityCategoryIcons.productLiabilityInsurance },
      { name: 'Public Liability Insurance', productIcon: LiabilityCategoryIcons.professionalIndemnity },
      { name: 'Trade Credit Insurance', productIcon: LiabilityCategoryIcons.tradeCreditInsurance },
      { name: 'Workmen Compensation', productIcon: LiabilityCategoryIcons.workmenCompensation },
    ],
  },

  {
    name: 'Other Insurances',
    activeColor: 'purple-200',
    theme: 'purple',
    mobileCardImage: MobileInsuranceCardImages.other,
    cardLink: '/products/other-insurances/',
    cardImages: InsuranceCardImages.other,
    description:
      'Other Insurances are designed to protect you from unforeseen expenses concerning varies areas including Pets, Kidnaps etc.',
    icon: productCategoryIcons.other,
    products: [
      {
        name: 'All Risks Insurance',
        productIcon: OtherhInsuranceImages.allrisksinsurance,
      },
      { name: 'Burglary Add on', productIcon: OtherhInsuranceImages.burglaryaddon },
      {
        name: 'Fidelity Guarantee Insurance',
        productIcon: OtherhInsuranceImages.cyberinsurance,
      },
      {
        name: 'Kidnap Ransom and Extortion',
        productIcon: OtherhInsuranceImages.kidnapransomandextortio,
      },
      { name: 'Money in Transit', productIcon: OtherhInsuranceImages.moneyintransit },
      { name: 'Pet Insurance', productIcon: OtherhInsuranceImages.petinsurance },
      { name: 'Fine Art Insurance', productIcon: OtherhInsuranceImages.fineartinsuranc },
      { name: 'Hni Insurance', productIcon: OtherhInsuranceImages.hniinsurance },
      { name: 'Household Policy', productIcon: OtherhInsuranceImages.householdPolicy },
    ],
  },
];
