import annualSalesTurnover from './annual-sales-turnover.png';
import openCover from './open-cover.png';
import sellersContingency from './sellers-contingency.png';
import singleTransit from './single-transit.png';

import other from './other.png';

export const MarineInsuranceImages = {
  annualSalesTurnover,
  openCover,
  sellersContingency,
  singleTransit,
  other,
};
