const Mission = () => {
  return (
    <div className="xl:mt-15 text-body-2 xl:text-body-1 container my-10 flex flex-col gap-y-3 xl:my-2 xl:block">
      <h2 className="heading-6">We are on a mission.</h2>
      <p className="mt-4">
        PrishaPolicy accelerates awareness about the advantages of insurance products among clients to service and
        reliably enhance the scope of insurance benefits to all customers.
      </p>
      <p className="mt-2">
        In this course, we also aim to create self-employment for thousands of youth in rural India by enabling them to
        sell insurance ethically.
      </p>
      <div>
        <div className="flex"></div>
      </div>
    </div>
  );
};

export default Mission;
