import allrisksinsurance from './all-risks-insurance.png';
import burglaryaddon from './burglary-add-on.png';
import cyberinsurance from './cyber-insurance.png';
import fidelityguaranteeinsurance from './fidelity-guarantee-insurance.png';
import fineartinsuranc from './fine-art-insurance.png';
import hniinsurance from './hni-insurance.png';
import kidnapransomandextortio from './kidnap-ransom-and-extortion.png';
import moneyintransit from './money-in-transit.png';
import petinsurance from './pet-insurance.png';
import householdPolicy from './household-policy.png';

export const OtherhInsuranceImages = {
  petinsurance,
  moneyintransit,
  kidnapransomandextortio,
  hniinsurance,
  fineartinsuranc,
  fidelityguaranteeinsurance,
  cyberinsurance,
  burglaryaddon,
  allrisksinsurance,
  householdPolicy,
};
