import LossAndProfit from './advanced-loss-of-profit.png';
import BoilerPressurePlant from './boiler-and-pressure-plant-machinery.png';
import ContractorsAllRisk from './contractors-all-risk.png';
import ContractorsPlant from './contractors-plant-and-machinery.png';
import Electronic from './electronic-equipment-insurance.png';
import EngineeringInsurance from './engineering-insurance.png';
import erection from './erection-all-risk.png';
import machineryBreakdown from './machinery-breakdown.png';
import machineryLossAndProfit from './machinery-loss-of-profit.png';
import contractorsPlantAndMachinery from './contractors-plant-and-machinery.png';

export const EngineeringInsuranceImages = {
  LossAndProfit,
  BoilerPressurePlant,
  ContractorsAllRisk,
  ContractorsPlant,
  Electronic,
  EngineeringInsurance,
  erection,
  machineryBreakdown,
  machineryLossAndProfit,
  contractorsPlantAndMachinery,
};
