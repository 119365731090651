import { ReactNode } from 'react';
import { RemixiconReactIconComponentType } from 'remixicon-react';
import CloseFillIcon from 'remixicon-react/CloseFillIcon';
import { twMerge } from 'tailwind-merge';
import Avatar from '../avatars/avatar';
import { UIColor } from '../colors';
export type BadgeColor = UIColor | 'grey';
export type BadgeSize = 'small' | 'medium' | 'large';
export type BadgeType = 'pill' | 'lozenge';

export type BadgeProps = {
  text?: ReactNode;
  pressArticleLink?: string;
  pressArticleLinkLabel?: string;
  theme?: BadgeColor;
  size?: BadgeSize;
  variant?: BadgeType;
  showDot?: true;
  dismissable?: boolean;
  imageURL?: string;
  // cursor?: string;
  icon?: RemixiconReactIconComponentType;
  iconClass?: string;
  leftSlot?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const bgColorMap: {
  [key in BadgeColor]: string;
} = {
  blue: 'blue-200',
  green: 'green-200',
  red: 'red-200',
  yellow: 'yellow-200',
  grey: 'surface-grey',
  teal: 'teal-200',
  purple: 'purple-100',
};

const textColorMap: {
  [key in BadgeColor]: string;
} = {
  blue: 'primary',
  green: 'green-700',
  red: 'red-800',
  yellow: 'yellow-900',
  grey: 'grey-700',
  teal: 'teal-700',
  purple: 'purple-600',
};

const heightMap: {
  [key in BadgeSize]: string;
} = {
  small: '20px',
  medium: '27px',
  large: '34px',
};

const yAxisPaddingMap: {
  [key in BadgeSize]: string;
} = {
  small: '2px',
  medium: '4px',
  large: '6px',
};

const xAxisPaddingMap: {
  [key in BadgeSize]: string;
} = {
  small: '8px',
  medium: '10px',
  large: '14px',
};

const borderColorMap: {
  [key in BadgeColor]: string;
} = {
  blue: 'blue-300',
  green: 'green-300',
  red: 'red-300',
  yellow: 'yellow-500',
  grey: 'grey-200',
  teal: 'teal-400',
  purple: 'purple-400',
};

const textSizeMap: {
  [key in BadgeSize]: string;
} = {
  small: 'body-5',
  medium: 'body-3',
  large: 'body-1',
};

const lozengeBorderRadiusMap: {
  [key in BadgeSize]: string;
} = {
  small: '4px',
  medium: '6px',
  large: '8px',
};

export const Badge = ({ showDot, ...props }: BadgeProps) => {
  const theme = props.theme || 'blue';
  const size = props.size || 'medium';
  const variant = props.variant ? props.variant : 'pill';
  const imageURL = props.imageURL;
  const bgColor = bgColorMap[theme];
  const textColor = textColorMap[theme];
  const height = heightMap[size];
  const yAxisPadding = yAxisPaddingMap[size];
  const xAxisPadding = xAxisPaddingMap[size];
  const borderColor = borderColorMap[theme];
  const lozengeBorderRadius = lozengeBorderRadiusMap[size];
  const textSize = textSizeMap[size];
  const dotSize = size === 'small' ? 6 : 8;

  return (
    <div
      {...props}
      className={twMerge(
        `flex w-fit max-w-full items-center gap-1.5 bg-${bgColor} text-${textColor} border-2 border-${borderColor} font-medium text-${textSize}`,
        props.className,
      )}
      style={{
        height,
        padding: `${yAxisPadding} ${xAxisPadding}`,
        borderRadius: variant === 'pill' ? '100px' : lozengeBorderRadius,
      }}
    >
      {props.leftSlot ? (
        props.leftSlot
      ) : imageURL ? (
        <Avatar imageURL={imageURL} fillType="image" variant={size === 'large' ? 'medium' : 'small'} shape="circle" />
      ) : props.icon ? (
        <props.icon className={twMerge(`text-${textColor} ${props.iconClass}`)} size={15} />
      ) : (
        showDot && (
          <div
            className={`bg-${textColor} rounded-full`}
            style={{
              height: dotSize,
              width: dotSize,
            }}
          />
        )
      )}
      <div className={`flex-1 truncate text-${textColor}`}>{props.text}</div>
      {props.pressArticleLink && <a href={props.pressArticleLink}>{props.pressArticleLinkLabel}</a>}
      {props.dismissable && <CloseFillIcon size={props.imageURL && size === 'large' ? 16 : 12} />}
    </div>
  );
};

export default Badge;
export * from './badge-set/badge-set';
