import parag from './parag-ved.png';
import rajesh from './rajesh-jain.png';
import sanjiv from './sanjiv-mantri.png';
import suwan from './suwan-murari.png';
import sandeep from './sandeep-molani.png';

export const TestimonialIcons = {
  suwan,
  sanjiv,
  rajesh,
  parag,
  sandeep,
};
